// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!normalize.css/normalize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js!@blueprintjs/core/lib/css/blueprint.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../node_modules/css-loader/dist/cjs.js!@voliere-fonts/raleway/dist/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../node_modules/css-loader/dist/cjs.js!@voliere-fonts/firacode/dist/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../node_modules/css-loader/dist/cjs.js!@voliere-fonts/quicksand/dist/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../node_modules/css-loader/dist/cjs.js!@voliere-fonts/montserrat/dist/index.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
// Module
exports.push([module.id, "/*\n * Copyright 2022 Palantir Technologies, Inc. All rights reserved.\n */\n.vo-login-view .logo-image-wrapper {\n  max-width: 64px;\n  border-radius: 64px;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n.vo-login-view .logo {\n  max-width: 64px;\n}\n.vo-login-view .title {\n  margin: 0;\n  font-variant: small-caps;\n  letter-spacing: 1px;\n  font-size: 18px;\n  color: #404854;\n  font-weight: normal;\n}\n.vo-login-view .title .brand {\n  padding: 0 10px / 4;\n}\n.vo-login-view .title .brand-accent {\n  padding: 0 10px / 4;\n  color: #8f99a8;\n}\n.bp5-dark .vo-login-view .title {\n  color: #e5e8eb;\n}\n.bp5-dark .vo-login-view .title .brand-accent {\n  color: #8f99a8;\n}\n", ""]);
// Exports
module.exports = exports;
