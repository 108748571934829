import { apps } from "./apps";
import { info } from "./info";
import { userAccount } from "@voliere/auth-components";
import { userAttributesReducer } from "@voliere/user-attributes";
import { notifications } from "@voliere/notifications";
export var reducers = {
  apps: apps,
  info: info,
  notifications: notifications,
  userAccount: userAccount,
  userAttributes: userAttributesReducer
};