// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!normalize.css/normalize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js!@blueprintjs/core/lib/css/blueprint.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../node_modules/css-loader/dist/cjs.js!@voliere-fonts/raleway/dist/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../node_modules/css-loader/dist/cjs.js!@voliere-fonts/firacode/dist/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../node_modules/css-loader/dist/cjs.js!@voliere-fonts/quicksand/dist/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../node_modules/css-loader/dist/cjs.js!@voliere-fonts/montserrat/dist/index.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
// Module
exports.push([module.id, "/*\n * Copyright 2022 Palantir Technologies, Inc. All rights reserved.\n */\n.stack-navbar-logo-image-wrapper {\n  width: 50px;\n  height: 50px;\n}\n.stack-navbar-logo-image-wrapper img {\n  max-height: 100%;\n  max-width: 100%;\n}\n", ""]);
// Exports
module.exports = exports;
