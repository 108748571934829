import { DEFAULT_APPS_STATE } from "./apps";
import { DEFAULT_USER_ACCOUNT_STATE } from "@voliere/auth-components";
import { DEFAULT_USER_ATTRIBUTES_STATE } from "@voliere/user-attributes";
import { DEFAULT_INFO_STATE } from "./info";
import { DEFAULT_NOTIFICATIONS_STATE } from "@voliere/notifications";
export var INITIAL_STATE = {
  apps: DEFAULT_APPS_STATE,
  info: DEFAULT_INFO_STATE,
  notifications: DEFAULT_NOTIFICATIONS_STATE,
  userAccount: DEFAULT_USER_ACCOUNT_STATE,
  userAttributes: DEFAULT_USER_ATTRIBUTES_STATE
};