import { defaultFetchable, fetchableActionTypes, fetchableFromPayload, makeFetchableActions } from "@majeure/fetchable";
import { baseURL, headers } from "./api";
export var DEFAULT_INFO_STATE = {
  stackInfo: defaultFetchable({
    emailPlaceholder: "email",
    logoColor: "1c2026"
  })
};
var InfoActions = {
  INFO: fetchableActionTypes("@@info", "Info")
};
var _makeFetchableActions = makeFetchableActions("Info", InfoActions.INFO),
  setInfo = _makeFetchableActions.setInfo,
  setInfoFetched = _makeFetchableActions.setInfoFetched,
  setInfoPending = _makeFetchableActions.setInfoPending,
  setInfoError = _makeFetchableActions.setInfoError;
export var fetchStackInfo = function fetchStackInfo() {
  return function (dispatch) {
    dispatch(setInfoPending(true));
    fetch("".concat(baseURL, "info/stack"), {
      method: "GET",
      headers: headers
    }).then(function (response) {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }).then(function (info) {
      dispatch(setInfo(info));
      dispatch(setInfoFetched(true));
      dispatch(setInfoPending(false));
    })["catch"](function (error) {
      console.warn("Could not fetch stack info", error);
      dispatch(setInfoFetched(true));
      dispatch(setInfoPending(false));
    });
  };
};
export var info = function info(state, action) {
  if (!state) {
    state = DEFAULT_INFO_STATE;
  }
  switch (action.type) {
    default:
      return state;
    case InfoActions.INFO.SET:
    case InfoActions.INFO.SET_ERROR:
    case InfoActions.INFO.SET_FETCHED:
    case InfoActions.INFO.SET_PENDING:
      return fetchableFromPayload(state, action, "stackInfo");
  }
};