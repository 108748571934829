import { defaultFetchable, fetchableActionTypes, fetchableFromPayload, makeFetchableActions } from "@majeure/fetchable";
import { baseURL, headers } from "./api";
export var DEFAULT_APPS_STATE = {
  apps: defaultFetchable([])
};
var AppsActions = {
  APPS: fetchableActionTypes("@@apps", "Apps")
};
var _makeFetchableActions = makeFetchableActions("Apps", AppsActions.APPS),
  setApps = _makeFetchableActions.setApps,
  setAppsPending = _makeFetchableActions.setAppsPending,
  setAppsFetched = _makeFetchableActions.setAppsFetched,
  setAppsError = _makeFetchableActions.setAppsError;
export var fetchApps = function fetchApps() {
  return function (dispatch) {
    dispatch(setAppsPending(true));
    fetch("".concat(baseURL, "apps/available"), {
      headers: headers
    }).then(function (response) {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    }).then(function (result) {
      dispatch(setApps(result));
      dispatch(setAppsPending(false));
      dispatch(setAppsFetched(true));
      dispatch(setAppsError(undefined));
    })["catch"](function (error) {
      dispatch(setAppsPending(false));
      dispatch(setAppsError(error));
      dispatch(setAppsFetched(true));
    });
  };
};
export var apps = function apps(state, action) {
  if (!state) {
    state = DEFAULT_APPS_STATE;
  }
  switch (action.type) {
    default:
      return state;
    case AppsActions.APPS.SET:
    case AppsActions.APPS.SET_FETCHED:
    case AppsActions.APPS.SET_PENDING:
    case AppsActions.APPS.SET_ERROR:
      return fetchableFromPayload(state, action, "apps");
  }
};