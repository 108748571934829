import { setCookieNames, writeCookies, setBaseUrl } from "@voliere/auth-components";
export var baseURL = "/api/";
if (window.location.hostname === "localhost") {
  baseURL = "http://localhost:4200/api/";
}

// set base URL for auth-components
setBaseUrl(baseURL);

// Set cookies names for auth-components
setCookieNames({
  username: "mj_u",
  session: "mj_s"
});
export var headers = new Headers();
headers.set("Content-Type", "application/json");
export function authorizeHeader(user, session) {
  headers.set("Authorization", "Bearer " + session);
  writeCookies(user, session);
}